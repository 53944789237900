import React from 'react'
import Helmet from 'react-helmet'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
let axios = require('axios')

// function encode(data) {
//   return Object.keys(data)
//     .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
//     .join('&')
// }

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false, messages:'' }
  }

  handleAlert=()=>{
    if(this.state.messages && this.state.messages!==''){
      return(`label is-tooltip-danger tooltip is-tooltip-active`);
    }else{
      return("label");
    }
  }

  getMessages=()=>{
    return(this.state.messages);
  }

  handleChange = e => {
    if(e.target.name==='message'){
      if(e.target.value.length>1){
        if(this.state.messages&&this.state.messages.length>0){
          this.setState({messages:'',storedMessages:this.state.messages})
        }
      }else{
        if(this.state.storedMessages&&this.state.storedMessages.length>0){
          this.setState({messages:this.state.storedMessages,storedMessages:''})
        }
      }
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    if(this.state['bot-field']&&this.state['bot-field'].trim()!==''){

    }else{
      axios.post('https://wesdata.com/api/contact-us', {
        name:this.state.name,
        email:this.state.email,
        message:this.state.message
      })
        .then((resp) => {
          let data=resp.data;
          if(data.response==='success'){
            this.setState({messages:''})
            navigate(form.getAttribute('action'));
          }else{
            this.setState({messages:data.message})
          }
        })
        .catch(error => alert(error))
    }
  }

  render() {
    return (
      <Layout>
        <Helmet>
            <title>WesData | Contact Us</title>
        </Helmet>
        <section className="section" >
          <div className="container">
            <div className="content">
              <h1>Contact Us</h1>
              <div className="columns">
                <div className="column is-6">
                  <div class="card">
                      <div class="card-image" style={{backgroundColor:'#406eb7',textAlign:'center',paddingTop:'1em',paddingBottom:'1em'}}>
                          <FontAwesomeIcon icon="paper-plane" size="5x" color="#fff"/>
                      </div>
                      <div class="card-content">
                          <div class="content">
                          <form 
                            name="contact"
                            method="post"
                            action="/contact/thanks/"
                            data-netlify="true"
                            data-netlify-honeypot="bot-field"
                            onSubmit={this.handleSubmit}
                          >
                            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                            <input type="hidden" name="form-name" value="contact" />
                            <div hidden>
                              <label>
                                Don’t fill this out:{' '}
                                <input name="bot-field" id="bot-field" onChange={this.handleChange} />
                              </label>
                            </div>
                            <div className="field">
                              <label className="label" htmlFor={'name'}>
                                Your name
                              </label>
                              <div className="control">
                                <input
                                  className="input"
                                  type={'text'}
                                  name={'name'}
                                  onChange={this.handleChange}
                                  id={'name'}
                                  required={true}
                                />
                              </div>
                            </div>
                            <div className="field">
                              <label className="label" htmlFor={'email'}>
                                Email
                              </label>
                              <div className="control">
                                <input
                                  className="input"
                                  type={'email'}
                                  name={'email'}
                                  onChange={this.handleChange}
                                  id={'email'}
                                  required={true}
                                />
                              </div>
                            </div>
                            <div className="field">
                              <label className={this.handleAlert()}
                                  data-tooltip={this.getMessages()} htmlFor={'message'}>
                                Message <span style={{fontSize:'.75em'}}>(include your phone number if you want us to call you back)</span>
                              </label>
                              <div className="control">
                                <textarea
                                  className="textarea"
                                  name={'message'}
                                  onChange={this.handleChange}
                                  id={'message'}
                                  required={false}
                                  rows="2"
                                />
                              </div>
                            </div>
                            <div className="field">
                              <button className="button is-link" type="submit">
                                Send
                              </button>
                            </div>
                          </form>
                          </div>
                      </div>
                  </div>
                </div>
                
                <div className="column is-6">
                  <div className="columns">
                      
                      <div className="column is-6" style={{height:"auto",paddingBottom:"0"}}>
                        {/*<div className="content" style={{textAlign:"center",backgroundColor:'#406eb7',color:"#fff"}}><h3 style={{color:"#fff",padding:".25em"}}>Stamford HQ</h3></div>*/}
                        
                          <div>
                            <a href="https://www.google.com/maps/place/750+E+Main+St,+Stamford,+CT+06902/@41.0555432,-73.5328104,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2a1c367ea78e9:0x36dcc3555cb1b820!8m2!3d41.0555432!4d-73.5306217" target="_blank"><img src="/img/hq_map.png" style={{width:"100%",height:"75%",minHeight:"256",border:"1px solid #ddd",boxShadow: "0 1px 1px 0 #eee"}} alt="WesData Stamford HQ"/></a>
                          </div>
                        
                      </div>
                      <div className="column is-6" style={{paddingBottom:".5em"}}>
                      <div class="card">
                          <div class="card-image" style={{backgroundColor:'#406eb7',textAlign:'center',paddingTop:'1em',paddingBottom:'1em'}}>
                              <FontAwesomeIcon icon="phone-volume" size="5x" color="#fff"/>
                          </div>
                          <div class="card-content">
                              

                              <div class="content" style={{textAlign:"center"}}>
                                <h3><a href="tel:+1-914-220-2153">+1-914-220-2153</a></h3>
                              </div>
                              
                          </div>
                        </div>
                      </div>
                  </div>
                  
                  <div class="content"></div>
                  <div class="card">
                    <div class="card-image" style={{backgroundColor:'#406eb7',textAlign:'center',paddingTop:'1em',paddingBottom:'1em'}}>
                    <FontAwesomeIcon icon="building" size="5x" color="#fff" style={{marginRight:".5em"}}/><span style={{color:"#fff",fontSize:"2em"}}>Locations</span>
                    </div>
                    <div class="card-content">
                    <div className="columns">
                      <div className="column is-6">
                        <div class="content">
                          <strong>Stamford HQ</strong><br/>
                          750 E Main St - Ste 620<br/>
                          Stamford, CT 06902
                        </div>
                      </div>
                      <div className="column is-6">
                        <div class="content">
                          <strong>Westchester</strong><br/>
                          282 Katonah Avenue, #215<br/>
                          Katonah, NY 10536
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>


                </div>
              </div>
              
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
